import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const AlbumPageTemplate = ({ title, content, contentComponent, image, description }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-6">
          <Link className="has-text-primary image" to="">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: image,
                        alt: `featured image thumbnail for post ${title}`,
                        width:
                          image.childImageSharp
                            .gatsbyImageData.width,
                        height:
                          image.childImageSharp
                            .gatsbyImageData.height,
                      }}
                      className=""
                    />
                    </Link>
              
          </div>
          <div className="column is-6">
          <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <h2 className="subtitle">August 30, 2016 • 12 songs, 36 min 55 sec </h2>
              <table className="table is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Length</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Sacred Guardian</td>
                    <td>2:37</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Aliyah</td>
                    <td>3:45</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Unless the Lord Builds the House</td>
                    <td>3:43</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Crazy</td>
                    <td>3:28</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Eliyahu</td>
                    <td>4:56</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>So My Soul</td>
                    <td>2:39</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Shabbat Shalom</td>
                    <td>3:42</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>The Lord is with Me</td>
                    <td>2:09</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Abba's Nigun</td>
                    <td>3:07</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>My Refuge</td>
                    <td>3:40</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>King of the Universe</td>
                    <td>1:20</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Priestly Blessing</td>
                    <td>1:44</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <PageContent className="content" content={content} />
              <h3 className="title is-size-3">Where to Listen</h3>
              <a className="has-text-primary p-1" href="https://open.spotify.com/album/38vKv0Oxiu6NewtCbRFO8f">Spotify</a> | 
              <a className="has-text-primary p-1" href="https://music.apple.com/us/album/laeli/1152958279">Apple Music</a> | 
              <a className="has-text-primary p-1" href="https://www.amazon.com/Laeli/dp/B01LWIE7B9">Amazon Music</a> |
              <a className="has-text-primary p-1" href="https://www.deezer.com/en/artist/10999936">Deezer</a> |
              <a className="has-text-primary p-1" href="https://music.youtube.com/playlist?list=OLAK5uy_n-Wy_0ApCc9K1DQCTp5WHugArj5sFotAc">YouTube Music</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AlbumPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AlbumPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Album | Laeli Music">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta name="og:url" content={`${data.markdownRemark.fields.slug}`} />
            <meta name="og:title" content={`${post.frontmatter.title}`} />
            <meta name="og:image" content={`${post.frontmatter.image}`} />
            <meta name="og:description" content={`${post.frontmatter.description}`} />
            <meta name="twitter:title" content={`${post.frontmatter.title}`} />
            <meta name="twitter:description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
